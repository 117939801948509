import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  data () {
    return {

    }
  },
  props: {
    options: {},
    height: '200px',
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}